// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-coaching-academy-tsx": () => import("./../src/pages/coachingAcademy.tsx" /* webpackChunkName: "component---src-pages-coaching-academy-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-courses-tsx": () => import("./../src/pages/courses.tsx" /* webpackChunkName: "component---src-pages-courses-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-online-academy-tsx": () => import("./../src/pages/onlineAcademy.tsx" /* webpackChunkName: "component---src-pages-online-academy-tsx" */),
  "component---src-pages-teacher-form-tsx": () => import("./../src/pages/teacherForm.tsx" /* webpackChunkName: "component---src-pages-teacher-form-tsx" */),
  "component---src-templates-blog-view-tsx": () => import("./../src/templates/blogView.tsx" /* webpackChunkName: "component---src-templates-blog-view-tsx" */),
  "component---src-templates-course-tsx": () => import("./../src/templates/course.tsx" /* webpackChunkName: "component---src-templates-course-tsx" */)
}

